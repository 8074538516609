<script setup lang="ts">
// icons
import { faHeart } from '@fortawesome/pro-solid-svg-icons';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiMapMarkerProps {
  campsite: any;
  showEffect?: boolean;
  isFavorite?: boolean;
  worstRating?: number;
  clusterAvailableCount?: number;
  isAsSearch?: boolean;
}
const props = withDefaults(defineProps<CiMapMarkerProps>(), {
  showEffect: false,
  isFavorite: false,
  worstRating: 1.0,
  clusterAvailableCount: 0,
  isAsSearch: false,
});

/********************
 * COMPOSITIONS      *
 ********************/
const { visibilityProducts } = useVisibilityProducts();

/********************
 * REFS & VARS       *
 ********************/
const delay = computed(() => {
  // Show best ratings first: interpolate between 100ms and 500ms
  const maxDelay = 500;
  const rating = props.campsite.rating_avg_overall || props.worstRating;

  return Math.floor(maxDelay / rating);
});

const availableCount = computed(() => {
  if (props.clusterAvailableCount > 9) {
    return '9+';
  }
  return props.clusterAvailableCount;
});
</script>

<template>
  <div class="map-marker__container h-[55px] w-11">
    <div
      :style="{ animationDelay: `${delay}ms` }"
      class="map-marker__animation size-full"
    >
      <div
        v-if="showEffect"
        class="map-marker__effect absolute block"
      />
      <div
        class="relative size-full"
        @click="$event.preventDefault()"
      >
        <svg
          class="map-marker__body absolute left-0 top-0 w-11 fill-white"
          width="44"
          height="54"
          viewBox="0 0 44 54"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.3262 42.7507C37.8747 39.7326 44 31.5816 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 31.5816 6.12534 39.7326 14.6738 42.7507L20.268 52.44C21.0378 53.7733 22.9623 53.7733 23.7321 52.44L29.3262 42.7507Z"
          />
        </svg>

        <CiRatingRadial
          :rating="parseFloat(campsite.rating_avg_overall)"
          class="map-marker__rating h-11 p-[3px]"
          font-class="font-medium"
        />

        <div
          v-if="isFavorite"
          class="absolute"
          :class="[
            visibilityProducts(props.campsite).hasConversionOptimization
              ? 'right-[10px] top-[-10px]'
              : '-top-2 right-[-2px]',
          ]"
        >
          <CiAwesomeIcon
            class="icon fill-danger"
            :icon="faHeart"
            ratio="0.6"
          />
        </div>
        <svg
          v-if="visibilityProducts(props.campsite).hasConversionOptimization"
          class="absolute -top-2 right-[-2px] z-20 fill-warning"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0.559998L10.7273 4.80616L15.6085 6.08786L12.4129 9.99384L12.7023 15.0321L8 13.2L3.29772 15.0321L3.5871 9.99384L0.391548 6.08786L5.27268 4.80616L8 0.559998Z"
          />
        </svg>

        <div
          v-if="availableCount"
          class="absolute z-20 flex size-[18px] items-center justify-center rounded-full bg-info text-center text-[0.6875rem] font-bold text-white"
          :class="{
            '-top-2 right-[-2px]': !visibilityProducts(props.campsite).hasConversionOptimization && !props.isFavorite,
            '-right-3 top-[-2px]': visibilityProducts(props.campsite).hasConversionOptimization,
            ' -right-3 top-[-2px]': props.isFavorite,
          }"
        >
          {{ availableCount }}
        </div>

        <div v-if="props.campsite.fanclub" class="absolute top-[30px]">
          <img
            src="~/assets/svg/fanclub-badge.svg"
            alt=""
            width="44"
            height="19"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.map-marker__container {
  .no-touch &:hover,
  &.active {
    .map-marker__body {
      @apply fill-primary;
    }

    :deep(.rating-radial) {
      .rating-radial__circular {
        @apply bg-primary;
      }
      .circle-bg {
        @apply stroke-white/25;
      }
      .circle.circle--primary {
        @apply stroke-white;
      }

      .circular-text {
        @apply text-white;
      }
    }
  }
}

.map-marker__body {
  filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25));
}

.map-marker__effect {
  animation: pulsate 10s ease-out infinite;
  background: rgba($primary, 0.6);
  border-radius: 50%;
  bottom: - calc(44px / 2);
  height: 44px;
  left: calc(44px / 2) - calc(44px / 2);
  opacity: 0;
  width: 44px;
  z-index: -1;
}

.map-marker__animation {
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-name: fade;
}
</style>
