export default function useMapSettings() {
  const runtimeConfig = useRuntimeConfig();
  const backendStore = useBackendStore();

  const mapLangMapping: { [key: string]: string } = {
    cs: 'e0d3eb58-4ab5-4b35-8e58-19ceba158879',
    de: 'a1bd910e-4e7b-4b80-8f8a-4142ab11e038',
    en: '550ad593-adfd-47b5-8d7e-dbdf79470c15',
    es: 'b67aba70-c638-4ad0-b4b6-98f88e6ca986',
    fr: '300d34c6-5831-405e-9163-02d214cdfda5',
    it: '3beead26-f304-40d7-8e44-f54fa4626f5d',
    nl: '2c264079-f624-47a7-82a2-2d3ab2973beb',
    pl: '45dc2092-6101-4cd2-98ac-65e70f8a5ec5',
    pt: '1a339899-c8d5-4ec3-a740-3564cd2e928f',
  };
  const MAP_KEY: string = runtimeConfig.public.maptilerKey;
  const MAP_TILES_URL_RASTER: string = `https://api.maptiler.com/maps/{mapId}/256/{z}/{x}/{y}.png?key=${MAP_KEY}`;
  const mapStaticPrefix = runtimeConfig.public.appVersion === 'local' ? backendStore.url : '';
  const MAP_STATIC_URL = `${mapStaticPrefix}/staticmap/{slug}/{size}.jpg`;
  const MAP_MIN_ZOOM: number = 4;
  const MAP_MAX_ZOOM: number = 18;
  const MAP_ATTRIBUTION: string
    = '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap</a> contributors';
  const MAP_TILEZIZE = 256;

  const getMapTilesUrl = (lang: string): string => {
    const mapId = mapLangMapping[lang] || mapLangMapping.en;
    return MAP_TILES_URL_RASTER.replace('{mapId}', mapId);
  };

  return {
    MAP_KEY,
    MAP_MIN_ZOOM,
    MAP_MAX_ZOOM,
    MAP_ATTRIBUTION,
    MAP_STATIC_URL,
    MAP_TILEZIZE,
    getMapTilesUrl,
  };
}
